// import { LIST_DOCUMENT_FETCH_WITHOUT_ID } from 'commons/constants/variable';
import {
  amendDeedTypeSchema,
  documentListSchema,
  listChildLocationSchema,
  listCitySchema,
  listCustomerTitleSchema,
  listOcupationSchema,
  masterRespSchema
} from 'schema';
import type {
  ChildLocationResponse,
  LocationResponse,
  CustomerTitleResponse,
  OcupationResponse,
  BusinessCategoryResponse,
  AnnualIncomeResponse,
  SourceOfFundResponse,
  AmendDeedTypeResponse,
  CharacterTypeShareholderResponse,
  NpwpOwnerShipResponse,
  CountryResponse,
  JobResponse,
  MonthlyIncomeResponse,
  DocumentListResponse,
  BankResponse,
  GeneralRiplayResponse,
  ManagementOccupationsResponse
} from 'schema';
import { BaseHttpClient } from 'service/http-base/BaseHttpClient';
import type { DocumentType, ListMasterDataV2, Response } from 'types';

export class MasterService extends BaseHttpClient {
  getCorporateClassification(): Promise<Response<ListMasterDataV2[]>> {
    return this.get('/p2p/api/funder/master/v2/corporate/types');
  }

  getCompanyClassification(): Promise<Response<ListMasterDataV2[]>> {
    return this.get('/p2p/api/funder/master/v2/company/types');
  }

  getCustomerTitle(): Promise<Response<CustomerTitleResponse[]>> {
    return this.get('/p2p/profile/customer-title', {
      responseSchema: listCustomerTitleSchema
    });
  }

  getLocations(locationLevel: string): Promise<Response<LocationResponse[]>> {
    return this.get(`/p2p/api/funder/location/level/${locationLevel}`, {
      responseSchema: listCitySchema
    });
  }

  getChildLocation(
    level: string,
    locationId: string
  ): Promise<Response<ChildLocationResponse[]>> {
    return this.get(
      `/p2p/api/funder/location/level-parent/${level}/${locationId}`,
      {
        responseSchema: listChildLocationSchema
      }
    );
  }

  getOccupations(): Promise<Response<OcupationResponse[]>> {
    return this.get('/p2p/api/funder/master/v2/occupation/positions', {
      responseSchema: listOcupationSchema
    });
  }

  getDocumentPreview(docType: DocumentType, docId: string): Promise<Blob> {
    return this.get(`/p2p/api/funder/documents/v2/${docType}/${docId}`, {
      isBlobFile: true
    });
  }

  getPostalCode(locationId: string): Promise<Response<string>> {
    return this.get(`/p2p/api/funder/location/post-code/${locationId}`);
  }

  getBusinessCategories(): Promise<Response<BusinessCategoryResponse[]>> {
    return this.get('/p2p/api/funder/master/v2/industries', {
      responseSchema: masterRespSchema
    });
  }

  getSubBusinessCategories(
    industryId: string
  ): Promise<Response<BusinessCategoryResponse[]>> {
    return this.get(`/p2p/api/funder/master/v2/industries/${industryId}`, {
      responseSchema: masterRespSchema
    });
  }

  getAnnualIncomes(): Promise<Response<AnnualIncomeResponse[]>> {
    return this.get('/p2p/api/funder/master/v2/company/annual-incomes', {
      responseSchema: masterRespSchema
    });
  }

  getSourceOfFunds(): Promise<Response<SourceOfFundResponse[]>> {
    return this.get('/p2p/api/funder/master/v2/company/source-of-funds', {
      responseSchema: masterRespSchema
    });
  }

  getAmendmentDeedType(): Promise<Response<AmendDeedTypeResponse[]>> {
    return this.get('/p2p/api/funder/master/v2/company/amend-deed-types', {
      responseSchema: amendDeedTypeSchema
    });
  }

  getCharaterTypeShareholders(): Promise<
    Response<CharacterTypeShareholderResponse[]>
  > {
    return this.get('/p2p/api/funder/master/v2/character/types', {
      responseSchema: masterRespSchema
    });
  }

  getNpwpOwnershipStatus(): Promise<Response<NpwpOwnerShipResponse[]>> {
    return this.get('/p2p/api/funder/master/v2/npwp-ownerships', {
      responseSchema: masterRespSchema
    });
  }

  getCountries(): Promise<Response<CountryResponse[]>> {
    return this.get('/p2p/api/funder/master/v2/countries', {
      responseSchema: masterRespSchema
    });
  }

  getUBOJobs(): Promise<Response<JobResponse[]>> {
    return this.get('/p2p/api/funder/master/v2/rdl/jobs', {
      responseSchema: masterRespSchema
    });
  }

  getUBOMonthlyIncomes(): Promise<Response<MonthlyIncomeResponse[]>> {
    return this.get('/p2p/api/funder/master/v2/rdl/monthly-incomes', {
      responseSchema: masterRespSchema
    });
  }

  getDocumentList(): Promise<Response<DocumentListResponse>> {
    return this.get('/p2p/api/funder/documents', {
      responseSchema: documentListSchema
    });
  }

  getDocumentByUrl(url: string): Promise<Blob> {
    return this.get(`/p2p/api/funder/documents/${url}`, {
      isBlobFile: true
    });
  }

  getBanks(): Promise<Response<BankResponse[]>> {
    return this.get('/p2p/api/funder/profile/bank-list');
  }

  getGeneralRiplays(): Promise<Response<GeneralRiplayResponse[]>> {
    return this.get('/p2p/api/funder/documents/v2/general-riplay-products');
  }

  getDocumentRiplayBySlug(slug: string): Promise<Blob> {
    return this.get(`/p2p/api/funder/documents/v2/${slug}`, {
      isBlobFile: true
    });
  }

  getOccupationManagements(): Promise<
    Response<ManagementOccupationsResponse[]>
  > {
    return this.get(
      '/p2p/api/funder/master/v2/management/occupation/positions',
      {
        responseSchema: masterRespSchema
      }
    );
  }

  postAckGeneralRiplayByDocType(docType: string): Promise<Response<unknown>> {
    return this.post(`/p2p/api/funder/documents/v2/${docType}/ack`);
  }
}
