import type {
  CompanyDataResponse,
  CompanyDocumentResponse,
  CompanyManagementResponse,
  CompanyRepresentativeResponse,
  ProfileCompanyResponse,
  SummaryCompanyDataResponse
} from 'schema';
import {
  companyManagementRespSchema,
  companyRepresentativeRespSchema
} from 'schema';
import { BaseHttpClient } from 'service/http-base/BaseHttpClient';
import type {
  ParamCompanyData,
  ParamCompanyDocument,
  ParamCompanyManagement,
  ParamCompanyRepresentative,
  ParamMembershipCommitment,
  Response
} from 'types';

export class ProfileService extends BaseHttpClient {
  getCompanyRepresentative(): Promise<Response<CompanyRepresentativeResponse>> {
    return this.get('/p2p/api/funder/v3/profile/institutional/company-pic', {
      responseSchema: companyRepresentativeRespSchema
    });
  }

  postCompanyRepresentative(
    params: ParamCompanyRepresentative
  ): Promise<Response<unknown>> {
    return this.post(
      '/p2p/api/funder/v3/profile/institutional/company-pic',
      params
    );
  }

  postCompanyData(params: ParamCompanyData): Promise<Response<unknown>> {
    return this.post(
      '/p2p/api/funder/v3/profile/institutional/company',
      params
    );
  }

  getCompanyData(): Promise<Response<CompanyDataResponse>> {
    return this.get('/p2p/api/funder/v3/profile/institutional/company');
  }

  postCompanyDocument(
    params: ParamCompanyDocument
  ): Promise<Response<unknown>> {
    return this.post(
      '/p2p/api/funder/v3/profile/institutional/company-doc',
      params
    );
  }

  getCompanyDocument(): Promise<Response<CompanyDocumentResponse>> {
    return this.get('/p2p/api/funder/v3/profile/institutional/company-doc');
  }

  postCompanyManagment(
    params: ParamCompanyManagement
  ): Promise<Response<unknown>> {
    return this.post(
      '/p2p/api/funder/v3/profile/institutional/company-management',
      params
    );
  }

  getCompanyManagement(): Promise<Response<CompanyManagementResponse>> {
    return this.get(
      '/p2p/api/funder/v3/profile/institutional/company-management',
      {
        responseSchema: companyManagementRespSchema
      }
    );
  }

  getCompanySummary(): Promise<Response<SummaryCompanyDataResponse>> {
    return this.get('/p2p/api/funder/v3/profile/institutional?validate=true');
  }

  postMembershipAgreement(
    params: ParamMembershipCommitment
  ): Promise<Response<{ waitingDays: number }>> {
    return this.post(
      '/p2p/api/funder/v3/profile/institutional/membership-commitment',
      params
    );
  }

  getCompanyProfile(): Promise<Response<ProfileCompanyResponse>> {
    return this.get('/p2p/api/funder/v3/profile/institutional');
  }
}
