import { REGEX_NUMBER_ONLY } from 'commons/constants/variable';
import { z } from 'zod';

export const formRegisterChooseTypeFunderSchema = z.object({
  citizenship: z.string().min(1),
  typeAccount: z.string()
});

export type FormRegisterChooseTypeFunder = z.infer<
  typeof formRegisterChooseTypeFunderSchema
>;

export const formRegisterInstitutionalFunderSchema = z
  .object({
    corporateTypeId: z.object({ id: z.string(), name: z.string() }).nullable(),
    corporateTypeOthers: z.string(),
    companyTypeId: z.object({ id: z.string(), name: z.string() }).nullable(),
    companyTypeOthers: z.string(),
    companyName: z.string().min(1, { message: 'regisAcc.label48' }),
    companyEmail: z.string().email({ message: 'regisAcc.label49' }),
    allowSubscription: z.boolean(),
    agreeTerm: z.boolean(),
    agreedToPojk111: z.boolean()
  })
  .refine((data) => {
    if (data.corporateTypeId?.id === '99') {
      return data.corporateTypeOthers.trim().length > 0;
    }
    return true;
  })
  .refine((data) => {
    if (data.companyTypeId?.id === '99') {
      return data.companyTypeOthers.trim().length > 0;
    }
    return true;
  });

export type FormRegisterInstitutionalFunder = z.infer<
  typeof formRegisterInstitutionalFunderSchema
>;

export const formRegisterRetailFunderSchema = z.object({
  email: z.string().email({ message: 'regisAcc.label49' }),
  relamiCode: z.string().nullable(),
  phone: z
    .string()
    .min(1, { message: 'register.label28' })
    .refine((value) => value.length >= 10 && value.length <= 12, {
      message: 'error.applicationUser.phone.length'
    })
});

export type FormRegisterRetailFunder = z.infer<
  typeof formRegisterRetailFunderSchema
>;

export const formTermConditionFunderSchema = z.object({
  allowSubscription: z.boolean(),
  agreeTerm: z.boolean(),
  agreeRejection: z.boolean(),
  agreedToPojk111: z.boolean()
});

export type FormTermConditionFunder = z.infer<
  typeof formTermConditionFunderSchema
>;

export const baseFormCompanyPICSchema = z.object({
  companyPoaDocId: z.string(),
  ktpDocId: z.string(),
  npwpDocId: z.string(),
  selfieDocId: z.string(),
  ktpNumber: z.coerce
    .string({
      required_error: 'completeProfile.label118'
    })
    .min(16, { message: 'completeProfile.label119' })
    .max(16, { message: 'completeProfile.label119' })
    .regex(REGEX_NUMBER_ONLY, { message: 'completeProfile.label120' }),
  npwpNumber: z
    .string()
    .nullable()
    .refine(
      (value) => !value || z.string().min(18).max(21).safeParse(value).success,
      {
        message: 'completeProfile.label245'
      }
    ),
  name: z.string(),
  ktpIssuingCity: z.string(),
  address: z.string(),
  rt: z.string().max(3, { message: 'completeProfile.label320' }),
  rw: z.string().max(3, { message: 'completeProfile.label321' }),
  birthPlace: z.string(),
  emailPic: z
    .string()
    .nullable()
    .refine((value) => !value || z.string().email().safeParse(value).success, {
      message: 'regisAcc.label49'
    }),
  emergencyPhoneName: z.string()
});

export type BaseFormCompanyPICFunder = z.infer<typeof baseFormCompanyPICSchema>;

export const formCompanyPICSchema = z
  .object({
    isCompanyPic: z.string(),
    title: z.object({ id: z.string(), name: z.string() }).nullable(),
    birthDate: z.string(),
    village: z.object({ id: z.string(), name: z.string() }).nullable(),
    occupationPosition: z
      .object({ id: z.string(), name: z.string(), key: z.string() })
      .nullable(),
    postalCode: z.string(),
    province: z.object({ id: z.string(), name: z.string() }).nullable(),
    city: z.object({ id: z.string(), name: z.string() }).nullable(),
    subdistrict: z.object({ id: z.string(), name: z.string() }).nullable(),
    phonePic: z
      .string()
      .refine((value) => !value || (value.length >= 10 && value.length <= 12), {
        message: 'error.applicationUser.phone.length'
      }),
    emergencyPhoneNumber: z
      .string()
      .refine((value) => !value || (value.length >= 10 && value.length <= 12), {
        message: 'error.applicationUser.phone.length'
      })
  })
  .merge(baseFormCompanyPICSchema);

export type FormCompanyPICFunder = z.infer<typeof formCompanyPICSchema>;

export const baseFormCompanyDataSchema = z.object({
  corporateTypeOthers: z.string().nullable(),
  companyTypeOthers: z.string().nullable(),
  companyName: z.string(),
  establishmentPlace: z.string(),
  establishmentDeedNumber: z.string(),
  companyNpwpDocId: z.string().nullable(),
  establishmentDeedDocId: z.string().nullable(),
  companyAddress: z.string(),
  companyEmail: z
    .string()
    .nullable()
    .refine((value) => !value || z.string().email().safeParse(value).success, {
      message: 'regisAcc.label49'
    }),
  companyWebsite: z
    .string()
    .nullable()
    .refine((value) => !value || z.string().url().safeParse(value).success, {
      message: 'completeProfile.label328'
    }),
  companyDesc: z.string().max(150, { message: 'Max character is 150' }),
  companySubIndustryOthers: z.string().nullable()
});

export type BaseFormCompanyDataFunder = z.infer<
  typeof baseFormCompanyDataSchema
>;

export const formCompanyDataSchema = z
  .object({
    establishmentDate: z.string(),
    establishmentDeedDate: z.string(),
    companyNpwpNumber: z
      .string()
      .nullable()
      .refine((value) => !value || (value.length >= 18 && value.length <= 21), {
        message: 'completeProfile.label245'
      }),
    companyPhone: z.string(),
    corporateType: z.object({ id: z.string(), name: z.string() }).nullable(),
    companyType: z.object({ id: z.string(), name: z.string() }).nullable(),
    companyProvince: z.object({ id: z.string(), name: z.string() }).nullable(),
    companyCity: z.object({ id: z.string(), name: z.string() }).nullable(),
    companyIndustry: z.object({ id: z.string(), name: z.string() }).nullable(),
    companySubIndustry: z
      .object({ id: z.string(), name: z.string() })
      .nullable(),
    annualIncome: z
      .object({ id: z.coerce.string(), name: z.string() })
      .nullable(),
    sourceOfFund: z
      .object({ id: z.coerce.string(), name: z.string() })
      .nullable()
  })
  .merge(baseFormCompanyDataSchema);

export type FormCompanyDataFunder = z.infer<typeof formCompanyDataSchema>;

export const amendmentDeedSchema = z.object({
  id: z.string().nullable(),
  typeKey: z
    .object({ id: z.string(), name: z.string(), key: z.string() })
    .nullable(),
  docId: z.string(),
  skMenkumhamDocId: z.string()
});

export type AmendmendDeed = z.infer<typeof amendmentDeedSchema>;

export const companyDocumentDetailsSchema = z.object({
  nibDocId: z.string().optional().nullable(),
  siupDocId: z.string().optional().nullable(),
  tdpDocId: z.string().optional().nullable(),
  trxReportDocId: z.string(),
  ndaDocId: z.string().optional().nullable(),
  pksDocId: z.string().optional().nullable(),
  skbDocId: z.string().optional().nullable()
});

export type CompanyDocument = z.infer<typeof companyDocumentDetailsSchema>;

export const formCompanyDocumentSchema = z
  .object({
    lastAmendmentDeedDate: z.string(),
    amendmentDeeds: z.array(amendmentDeedSchema),
    hasNib: z.string(),
    nibNumber: z
      .string()
      .optional()
      .nullable()
      .refine(
        (value) => !value || z.string().max(13).safeParse(value).success,
        {
          message: 'completeProfile.label323'
        }
      ),
    siupNumber: z
      .string()
      .optional()
      .nullable()
      .refine(
        (value) => !value || z.string().max(50).safeParse(value).success,
        {
          message: 'completeProfile.label324'
        }
      ),
    tdpNumber: z
      .string()
      .optional()
      .nullable()
      .refine(
        (value) => !value || z.string().max(50).safeParse(value).success,
        {
          message: 'completeProfile.label325'
        }
      ),
    skbStartDate: z.string().optional().nullable(),
    skbEndDate: z.string().optional().nullable()
  })
  .merge(companyDocumentDetailsSchema);

export type FormCompanyDocumentFunder = z.infer<
  typeof formCompanyDocumentSchema
>;

const coManagementFormSchema = z.object({
  id: z.string().optional(),
  name: z.string().nullable(),
  citizenshipId: z.string().nullable(),
  ktpNumber: z.coerce
    .string()
    .nullable()
    .refine(
      (value) =>
        !value ||
        z.string().min(16).max(16).regex(REGEX_NUMBER_ONLY).safeParse(value)
          .success,
      {
        message: 'completeProfile.label246'
      }
    ),
  ktpDocId: z.string().optional().nullable(),
  npwpNumber: z
    .string()
    .nullable()
    .refine(
      (value) => !value || z.string().min(18).max(21).safeParse(value).success,
      {
        message: 'completeProfile.label245'
      }
    ),
  npwpDocId: z.string().optional().nullable(),
  npwpOwnership: z
    .object({ id: z.string(), name: z.string(), key: z.string() })
    .nullable(),
  passportNumber: z
    .string()
    .nullable()
    .refine((value) => !value || z.string().max(25).safeParse(value).success, {
      message: 'completeProfile.label326'
    }),
  passportDocId: z.string().optional().nullable(),
  country: z.object({ id: z.string(), name: z.string() }).nullable(),
  occupationPosition: z.object({ id: z.string(), name: z.string() }).nullable(),
  occupationPositionOthers: z.string().nullable(),
  email: z
    .string()
    .nullable()
    .refine((value) => !value || z.string().email().safeParse(value).success, {
      message: 'regisAcc.label49'
    })
});

export type CoManagementForm = z.infer<typeof coManagementFormSchema>;

const shareholderFormSchema = z.object({
  id: z.string().optional(),
  characterType: z.object({ id: z.string(), name: z.string() }).nullable(),
  citizenshipId: z.string().nullable(),
  name: z.string().nullable(),
  ktpNumber: z.coerce
    .string()
    .nullable()
    .refine(
      (value) =>
        !value ||
        z.string().min(16).max(16).regex(REGEX_NUMBER_ONLY).safeParse(value)
          .success,
      {
        message: 'completeProfile.label246'
      }
    ),
  ktpDocId: z.string().optional().nullable(),
  npwpNumber: z.coerce
    .string()
    .nullable()
    .refine(
      (value) =>
        !value ||
        z
          .string()
          .min(18, { message: 'completeProfile.label245' })
          .max(21, { message: 'completeProfile.label245' })
          .safeParse(value).success,
      {
        message: 'completeProfile.label245'
      }
    ),
  npwpDocId: z.string().optional().nullable(),
  npwpOwnership: z
    .object({ id: z.string(), name: z.string(), key: z.string() })
    .nullable(),
  passportNumber: z
    .string()
    .nullable()
    .refine((value) => !value || z.string().max(25).safeParse(value).success, {
      message: 'completeProfile.label326'
    }),
  passportDocId: z.string().optional().nullable(),
  country: z.object({ id: z.string(), name: z.string() }).nullable(),
  ownershipPercentage: z.number().nullable()
});

export type ShareholderForm = z.infer<typeof shareholderFormSchema>;

const uboFormSchema = z.object({
  id: z.string().optional(),
  name: z.string().nullable(),
  citizenshipId: z.string().nullable(),
  ktpNumber: z.coerce
    .string()
    .nullable()
    .refine(
      (value) =>
        !value ||
        z.string().min(16).max(16).regex(REGEX_NUMBER_ONLY).safeParse(value)
          .success,
      {
        message: 'completeProfile.label246'
      }
    ),
  ktpDocId: z.string().optional().nullable(),
  passportNumber: z
    .string()
    .nullable()
    .refine((value) => !value || z.string().max(25).safeParse(value).success, {
      message: 'completeProfile.label326'
    }),
  passportDocId: z.string().optional().nullable(),
  rdlJob: z.object({ id: z.string(), name: z.string() }).nullable(),
  countryId: z.object({ id: z.string(), name: z.string() }).nullable(),
  rdlJobOthers: z.string().optional().nullable(),
  sourceOfFund: z.object({ id: z.string(), name: z.string() }).nullable(),
  rdlMonthlyIncome: z.object({ id: z.string(), name: z.string() }).nullable(),
  customerRelationId: z.string().nullable(),
  supportingDocId: z.string().optional().nullable()
});

export type UboForm = z.infer<typeof uboFormSchema>;

export const formCompanyManagementSchema = z.object({
  coManagements: z.array(coManagementFormSchema),
  shareholders: z.array(shareholderFormSchema),
  ubo: uboFormSchema
});

export type FormCompanyManagementFunder = z.infer<
  typeof formCompanyManagementSchema
>;

export const formAddBankAccountFunderSchema = z.object({
  bankType: z.object({ id: z.string(), name: z.string() }).nullable(),
  ownerAccount: z.string(),
  password: z.string(),
  fullName: z.string()
});

export type FormAddBankAccountFunder = z.infer<
  typeof formAddBankAccountFunderSchema
>;

export const formCommitmentMembershipSchema = z.object({
  agreement: z.boolean(),
  agreeRejection: z.boolean()
});

export type FormCommitmentMembership = z.infer<
  typeof formCommitmentMembershipSchema
>;

/** response rest API schema */

const errorRespSchema = z.object({
  errorField: z.string(),
  errorMessage: z.string(),
  errorLongText: z.string()
});

export type ErrorResponseForm = z.infer<typeof errorRespSchema>;

const formStatusSchema = z.object({
  isValid: z.boolean(),
  errorFields: z.array(errorRespSchema),
  rejectedFields: z.array(errorRespSchema)
});

export const verifyUserRespSchema = z.object({
  isVerify: z.boolean().nullable(),
  isNewVerified: z.boolean().nullable(),
  isDigisignActive: z.boolean().nullable(),
  isRdlActive: z.boolean().nullable(),
  hasBankAccount: z.boolean().nullable(),
  needRetakeOcr: z.boolean().nullable(),
  needChangeProfile: z.boolean().nullable(),
  isVerificationRejected: z.boolean().nullable(),
  changeProfileOnSteps: z.array(z.number()).nullable(),
  verificationWaitingDays: z.number().nullable(),
  funderId: z.string().nullable(),
  name: z.string().nullable(),
  gender: z.number().nullable(),
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  nationality: z.number().nullable(),
  userType: z.number().nullable(),
  accountNumber: z.string().nullable(),
  rejectComment: z.string().nullable(),
  lastRepaymentDate: z.date().nullable(),
  agreementSignedDate: z.string().nullable(),
  taxRate: z.number().nullable(),
  profileStep: z.number().nullable(),
  rdlEducationLevelId: z.string().nullable(),
  rdlMonthlyIncomeId: z.string().nullable(),
  completeProfile: z.number().nullable(),
  pin: z.string().nullable(),
  isPinValid: z.boolean().nullable(),
  pinFailedCount: z.number().nullable(),
  canChangePinIn: z.number().nullable(),
  restrictedUser: z.boolean().nullable(),
  usingAkadPayung: z.boolean().nullable(),
  isFunderFI: z.boolean().nullable()
});

export type VerifyUserResp = z.infer<typeof verifyUserRespSchema>;

export const companyRepresentativeRespSchema = z.object({
  address: z.string().nullable(),
  birthDate: z.string().nullable(),
  cityId: z.string().nullable(),
  companyPoaDocId: z.string().nullable(),
  emailPic: z.string().nullable(),
  emergencyPhoneName: z.string().nullable(),
  emergencyPhoneNumber: z.string().nullable(),
  isCompanyPic: z.boolean(),
  birthPlace: z.string(),
  provinceId: z.string().nullable(),
  ktpDocId: z.string().nullable(),
  ktpIssuingCity: z.string().nullable(),
  ktpNumber: z.string().nullable(),
  name: z.string().nullable(),
  npwpDocId: z.string().nullable(),
  npwpNumber: z.string().nullable(),
  occupationPositionKey: z.string().nullable(),
  phonePic: z.string().nullable(),
  rt: z.string().nullable(),
  rw: z.string().nullable(),
  selfieDocId: z.string().nullable(),
  subdistrictId: z.string().nullable(),
  titleId: z.string().nullable(),
  villageId: z.string().nullable(),
  formStatus: formStatusSchema
});

export type CompanyRepresentativeResponse = z.infer<
  typeof companyRepresentativeRespSchema
>;

export const CustomerTitleResponseSchema = z.object({
  id: z.string(),
  description: z.string(),
  descriptionEn: z.string(),
  jenisKelamin: z.string().nullable()
});

export const listCustomerTitleSchema = z.array(CustomerTitleResponseSchema);

export type CustomerTitleResponse = z.infer<typeof CustomerTitleResponseSchema>;

export const masterRespSchema = z.object({
  id: z.string(),
  name: z.string()
});

export const listCitySchema = z.array(masterRespSchema);

export type LocationResponse = z.infer<typeof masterRespSchema>;

export type BusinessCategoryResponse = z.infer<typeof masterRespSchema>;

export type AnnualIncomeResponse = z.infer<typeof masterRespSchema>;

export type SourceOfFundResponse = z.infer<typeof masterRespSchema>;

export type CharacterTypeShareholderResponse = z.infer<typeof masterRespSchema>;

export type CountryResponse = z.infer<typeof masterRespSchema>;

export type JobResponse = z.infer<typeof masterRespSchema>;

export type MonthlyIncomeResponse = z.infer<typeof masterRespSchema>;

export type ManagementOccupationsResponse = z.infer<typeof masterRespSchema>;

export const amendDeedTypeSchema = z
  .object({
    key: z.string()
  })
  .merge(masterRespSchema);

export type AmendDeedTypeResponse = z.infer<typeof amendDeedTypeSchema>;

export const npwpOwnerShipSchema = z
  .object({
    key: z.string()
  })
  .merge(masterRespSchema);

export type NpwpOwnerShipResponse = z.infer<typeof npwpOwnerShipSchema>;

export const childLocationSchema = z
  .object({
    postalCode: z.string()
  })
  .merge(masterRespSchema);

export const listChildLocationSchema = z.array(childLocationSchema);

export type ChildLocationResponse = z.infer<typeof childLocationSchema>;

export const occupationSchema = z.object({
  id: z.number(),
  name: z.string(),
  orderNumber: z.number(),
  key: z.string()
});

export const listOcupationSchema = z.array(occupationSchema);

export type OcupationResponse = z.infer<typeof occupationSchema>;

export const ocrKTPSchema = z.object({
  address: z.string(),
  birthPlace: z.string(),
  birthDate: z.string(),
  city: z.string(),
  expiryDate: z.string(),
  ktpNumber: z.string(),
  location1Id: z.string().nullable(),
  location2Id: z.string().nullable(),
  location3Id: z.string().nullable(),
  location4Id: z.string().nullable(),
  name: z.string(),
  rt: z.string(),
  rw: z.string()
});

export type OcrKTPResponse = z.infer<typeof ocrKTPSchema>;

export const masterDataCompanyDataFormSchema = z.object({
  isLoading: z.boolean(),
  corporates: z.array(masterRespSchema),
  companies: z.array(masterRespSchema),
  provinces: z.array(masterRespSchema),
  businessCategories: z.array(masterRespSchema),
  annualIncomes: z.array(masterRespSchema),
  sourceOfFunds: z.array(masterRespSchema)
});

export type MasterDataCompanyDataForm = z.infer<
  typeof masterDataCompanyDataFormSchema
>;

export const companyDataRespSchema = z.object({
  corporateTypeId: z.string().nullable(),
  corporateTypeOthers: z.string().nullable(),
  companyTypeId: z.string().nullable(),
  companyTypeOthers: z.string().nullable(),
  companyName: z.string().nullable(),
  establishmentPlace: z.string().nullable(),
  establishmentDate: z.string().nullable(),
  establishmentDeedNumber: z.string().nullable(),
  establishmentDeedDate: z.string().nullable(),
  establishmentDeedDocId: z.string().nullable(),
  companyNpwpDocId: z.string().nullable(),
  companyAddress: z.string().nullable(),
  companyProvinceId: z.string().nullable(),
  companyCityId: z.string().nullable(),
  companyEmail: z.string().nullable(),
  companyPhone: z.string().nullable(),
  companyWebsite: z.string().nullable(),
  companyDesc: z.string().nullable(),
  companyNpwpNumber: z.string().nullable(),
  companyIndustryId: z.string().nullable(),
  companySubIndustryId: z.string().nullable(),
  companySubIndustryOthers: z.string().nullable(),
  subdistrictId: z.string().nullable(),
  annualIncomeId: z.number().nullable(),
  sourceOfFundId: z.string().nullable(),
  formStatus: formStatusSchema
});

export type CompanyDataResponse = z.infer<typeof companyDataRespSchema>;

export const amendmentDeedRespSchema = z.object({
  id: z.string().nullable(),
  typeKey: z.string().nullable(),
  docId: z.string(),
  skMenkumhamDocId: z.string()
});

export type AmendDeedResponse = z.infer<typeof amendmentDeedRespSchema>;

export const companyDocumentRespSchema = z
  .object({
    lastAmendmentDeedDate: z.string(),
    amendmentDeeds: z.array(amendmentDeedRespSchema),
    hasNib: z.boolean(),
    nibNumber: z.string().optional().nullable(),
    siupNumber: z.string().optional().nullable(),
    tdpNumber: z.string().optional().nullable(),
    skbStartDate: z.string().optional().nullable(),
    skbEndDate: z.string().optional().nullable(),
    formStatus: formStatusSchema
  })
  .merge(companyDocumentDetailsSchema);

export type CompanyDocumentResponse = z.infer<typeof companyDocumentRespSchema>;

const coManagementRespSchema = z.object({
  id: z.string().optional(),
  name: z.string().nullable(),
  citizenshipId: z.string().nullable(),
  ktpNumber: z.string().nullable(),
  ktpDocId: z.string(),
  npwpNumber: z.string().nullable(),
  npwpDocId: z.string(),
  npwpOwnershipKey: z.string().nullable(),
  passportNumber: z.string().nullable(),
  passportDocId: z.string(),
  countryId: z.string().nullable(),
  occupationPositionId: z.string().nullable(),
  occupationPositionOthers: z.string().nullable(),
  email: z.string().nullable()
});

export type CoManagementResponse = z.infer<typeof coManagementRespSchema>;

const shareholderRespSchema = z.object({
  id: z.string().optional(),
  characterTypeId: z.string().nullable(),
  citizenshipId: z.string().nullable(),
  name: z.string().nullable(),
  ktpNumber: z.string().nullable(),
  ktpDocId: z.string(),
  npwpNumber: z.string().nullable(),
  npwpDocId: z.string(),
  npwpOwnershipKey: z.string().nullable(),
  passportNumber: z.string().nullable(),
  passportDocId: z.string(),
  countryId: z.string().nullable(),
  ownershipPercentage: z.number().nullable()
});

export type ShareholderResponse = z.infer<typeof shareholderRespSchema>;

const uboRespSchema = z.object({
  id: z.string().optional(),
  name: z.string().nullable(),
  citizenshipId: z.string().optional(),
  ktpNumber: z.string().nullable(),
  ktpDocId: z.string(),
  passportNumber: z.string().nullable(),
  passportDocId: z.string(),
  rdlJobId: z.string().nullable(),
  countryId: z.string().nullable(),
  rdlJobOthers: z.string().nullable(),
  sourceOfFundId: z.string().nullable(),
  rdlMonthlyIncomeId: z.string().nullable(),
  customerRelationKey: z.string().nullable(),
  supportingDocId: z.string()
});

export type UboResponse = z.infer<typeof uboRespSchema>;

export const companyManagementRespSchema = z.object({
  coManagements: z.array(coManagementRespSchema),
  shareholders: z.array(shareholderRespSchema),
  ubo: uboRespSchema,
  formStatus: formStatusSchema
});

export type CompanyManagementResponse = z.infer<
  typeof companyManagementRespSchema
>;

export const SummaryCompanyDataResponseSchema = z.object({
  companyPic: companyRepresentativeRespSchema,
  company: companyDataRespSchema,
  companyDocument: companyDocumentRespSchema,
  companyManagement: companyManagementRespSchema
});

export type SummaryCompanyDataResponse = z.infer<
  typeof SummaryCompanyDataResponseSchema
>;

export const documentSchema = z.object({
  docUrl: z.string(),
  docTitle: z.string(),
  taxNumber: z.string().nullable(),
  taxPeriod: z.string().nullable(),
  taxYear: z.string().nullable(),
  taxYearAndPeriod: z.string().nullable()
});

export const documentListSchema = z.object({
  fundingApprovals: z.array(documentSchema),
  manualAkads: z.array(documentSchema),
  membershipAgreement: documentSchema,
  nda: documentSchema,
  pks: documentSchema,
  taxSlips: z.array(documentSchema),
  umbrellaAkads: z.array(documentSchema)
});

export type DocumentListResponse = z.infer<typeof documentListSchema>;

export const bankListSchema = z.object({
  cleringCode: z.string(),
  code: z.string(),
  id: z.string(),
  isntamoneyCode: z.string(),
  name: z.string(),
  rtgsCode: z.string()
});

export type BankResponse = z.infer<typeof bankListSchema>;

export const accountBankSchema = z.object({
  bankId: z.string(),
  ownerAccount: z.string(),
  ownerName: z.string(),
  success: z.boolean(),
  match: z.string()
});

export type AccountBankResponse = z.infer<typeof accountBankSchema>;

export const companyPICProfileSchema = companyRepresentativeRespSchema.omit({
  formStatus: true
});
export const companyDataProfileSchema = companyDataRespSchema.omit({
  formStatus: true
});
export const companyDocumentProfileSchema = companyDocumentRespSchema.omit({
  formStatus: true
});
export const companyManagementProfileSchema = companyManagementRespSchema.omit({
  formStatus: true
});

export const ProfileCompanyDataResponseSchema = z.object({
  companyPic: companyPICProfileSchema,
  company: companyDataProfileSchema,
  companyDocument: companyDocumentProfileSchema,
  companyManagement: companyManagementProfileSchema
});

export type ProfileCompanyResponse = z.infer<
  typeof ProfileCompanyDataResponseSchema
>;

export type CompanyPICProfileResponse = z.infer<typeof companyPICProfileSchema>;

export type CompanyDataProfileResponse = z.infer<
  typeof companyDataProfileSchema
>;

export type CompanyDocumentProfileResponse = z.infer<
  typeof companyDocumentProfileSchema
>;

export type CompanyManagementProfileResponse = z.infer<
  typeof companyManagementProfileSchema
>;

/** RIPLAY */
export const generalRiplaySchema = z.object({
  name: z.string(),
  slug: z.string(),
  urlPath: z.string()
});

export type GeneralRiplayResponse = z.infer<typeof generalRiplaySchema>;

export const formAgreementRiplaySchema = z.object({
  agreement: z.boolean(),
  agreeRiskFunding: z.boolean()
});

export type FormAgreementRiplay = z.infer<typeof formAgreementRiplaySchema>;
