import React, { PropsWithChildren, useEffect } from 'react';
import classNames from 'classnames';

import { overflowHide } from 'utils/overflowHide';

export interface ModalProps {
  isShow: boolean;
  isLoadingProgress?: boolean;
  onClose?: () => void;
  className?: string;
}

const Modal: React.FC<PropsWithChildren & ModalProps> = ({
  children = null,
  isShow,
  isLoadingProgress = false,
  onClose = () => {},
  className = ''
}) => {
  useEffect(() => {
    const modalOpen = document.getElementsByClassName('modal-open');

    overflowHide(modalOpen.length !== 0);

    return () => {
      overflowHide(false);
    };
  }, [isShow]);

  return isShow ? (
    <div
      className={classNames('modal items-center', {
        'modal-open': isShow,
        [`!z-[999999]`]: isLoadingProgress,
        [`${className}`]: true
      })}
      onClick={() => {
        return onClose();
      }}
      aria-hidden
    >
      {children}
    </div>
  ) : null;
};

export default Modal;
