const features = {
  /*
   * @feature ECO-3664
   * Re-assessment for every financing request
   *
   * @feature ECO-3094
   * Live Chat Feature
   *
   * @feature ECO-3903
   * Financing report feature
   *
   */
  reAssessmentFeature:
    window.__RUNTIME_CONFIG__.FF_BENEF_ECO_REASSESSMENT === 'true',
  liveChatFeature: window.__RUNTIME_CONFIG__.FF_BENEF_ECO_LIVECHAT === 'true',
  financingReportFeature:
    window.__RUNTIME_CONFIG__.FF_BENEF_ECO_FINANCINGREPORT === 'true',
  signupAgriFeature: window.__RUNTIME_CONFIG__.FF_BENEF_ECO_AGRI === 'true',
  registerInstitutionalFunder:
    window.__RUNTIME_CONFIG__.FF_REGISTRATION_INSTITUTIONAL_FUNDER === 'true',
  riplayDocumentFeature: window.__RUNTIME_CONFIG__.FF_RIPLAY_DOCUMENT === 'true'
};

export default features;
