import type { OcrKTPResponse } from 'schema';
import { BaseHttpClient } from 'service/http-base/BaseHttpClient';
import type { Response } from 'types';

export class UploadService extends BaseHttpClient {
  postUploadDocument(file: FormData): Promise<Response<{ id: string }>> {
    return this.post('/p2p/api/funder/documents/v2/upload', file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  postOCRKTP(param: { docId: string }): Promise<Response<OcrKTPResponse>> {
    return this.post('/p2p//api/funder/documents/v2/ktp/ocr', param);
  }
}
