import type { DocumentType, OCRData } from 'types';
import type { ProfileCompanyResponse } from 'schema';

import { AUTHENTICATION_TYPE } from '../authenticationType';
import { ROUTER_BASE_URL } from '../routePath';

/* for key localstorage or cookie */
export const AUTH_USER = 'AUTH_USER';
export const APPS_INFO = 'APPS_INFO';
export const I18NEXT_LANG = 'I18NEXT_LANG';
export const I18NEXT_VERSION = 'I18NEXT_VERSION';
export const I18NEXT_MESSAGE_ID = 'I18NEXT_MESSAGE_ID';
export const I18NEXT_MESSAGE_EN = 'I18NEXT_MESSAGE_EN';
export const PORTFOLIO_TAX = 'PORTFOLIO_TAX';
export const USER_VERIFY = 'USER_VERIFY';
export const VOTES = 'VOTES';
/* ===== */

/* Status Funders */
export const FUNDING_DONE = ['funding.statusFund4', 'Selesai'];
export const FUNDING_WAIT_CONFIRMATION = [
  'funding.statusFund5',
  'Menunggu Konfirmasi'
];
export const FUNDING_FAILED = ['funding.statusFund6', 'Pembiayaan Gagal'];
export const FUNDING_FULL_REPAYMENT = [
  'funding.statusFund3',
  'Pelunasan Penuh'
];
export const FUNDING_WAIT_REPAYMENT = [
  'funding.statusFund2',
  'Menunggu Pelunasan'
];
export const FUNDIND_WAIT_DISBURSEMENT = [
  'funding.statusFund1',
  'Menunggu Pencairan'
];
export const FUNDING_WAIT_REPAYMENT2 = [
  'funding.statusFund7',
  'Menunggu Pembayaran'
];
export const FUNDING_WAIT_POA = [
  'funding.statusFund8',
  'Menunggu POA dari Admin'
];
export const FUNDING_WAIT_DIGISIGN = [
  'funding.statusFund9',
  'Menunggu Aktivasi DigiSign'
];
export const FUNDING_CONTINUE_SIGNATURE = [
  'funding.dtlLabel220',
  'Lanjutkan Tanda Tangan'
];
export const FUNDING_APPROVE = ['funding.dtlLabel221', 'Setujui Pendanaaan'];
export const FUNDING_PARTIAL_REPAYMENT = [
  'funding.dtlLabel257',
  'Pengembalian Dana Bertahap'
];
/* ====== */

/* for akad funder badan hukum */
export const UMBRELLA_AKAD = 'UMBRELLA_AKAD';
export const MANUAL_AKAD = 'MANUAL_AKAD';

/* Calendar Type */
export const CALENDAR_DISTRIBUTION = 'disburse_date';
export const CALENDAR_DUEDATE = 'due_date';
export const CALENDAR_FUND = 'amount_booked_date';
export const CALENDAR_RETURN = 'repayment_date';
/* ====== */

/* Regex */
export const REGEX_FORMAT_EMAIL = new RegExp(
  /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
);
export const REGEX_NPWP_MASK = new RegExp(
  /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/g
);
export const REGEX_ONLY_NUM = new RegExp(/[^\d]/g);
export const REGEX_PHONE_VALID = new RegExp(/^(\+62|62|0)8[1-9][0-9]{6,10}/i);
export const REGEX_TITLE_CASE = new RegExp(/\w\S*/g);
export const REGEX_WITHOUT_LEADING_ZERO = new RegExp(/^0+/g);
export const REGEX_WITHOUT_THOUSAND = new RegExp(/[.]+/g);
export const REGEX_CHAR_CONTAINS_NUM = new RegExp(/(?=.*[0-9])/g);
export const REGEX_CHAR_CONTAINS_UPPER_LOWER = new RegExp(
  /(?=.*[a-z])(?=.*[A-Z])/g
);
export const REGEX_LETTER_AND_NUMBER = new RegExp(
  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/
);
export const REGEX_UPPERCASE_LOWERCASE = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])/);
export const REGEX_REMOVE_BASENAME_PATH = new RegExp(ROUTER_BASE_URL, 'i');
export const REGEX_REMOVE_NON_NUMERIC = new RegExp(/\D/g);
export const REGEX_LEADING_ZERO = new RegExp(/^0+/);
export const REGEX_WORD_TAG_PATTERN = new RegExp(/<+(\w+)>+/g);
export const REGEX_NUMBER_ONLY = new RegExp('^[0-9]*$');
export const REGEX_CO_MANAGEMENT_OCCUPATION =
  /coManagements\[(\d+)\]\.occupationPositionId/;
export const REGEX_CO_MANAGEMENT_NPWP =
  /coManagements\[(\d+)\]\.npwpOwnershipKey/;
export const REGEX_CO_MANAGEMENT_COUNTRY = /coManagements\[(\d+)\]\.countryId/;
export const REGEX_SHAREHOLDER_NPWP = /shareholders\[(\d+)\]\.npwpOwnershipKey/;
export const REGEX_SHAREHOLDER_CHAR = /shareholders\[(\d+)\]\.characterTypeId/;
export const REGEX_SHAREHOLDER_COUNTRY = /shareholders\[(\d+)\]\.countryId/;
export const REGEX_SHAREHOLDER_CITIZENSHIP =
  /shareholders\[(\d+)\]\.citizenship/;
export const REGEX_RDL_JOB_UBO = /ubo.rdlJobId/;
export const REGEX_RDL_MONTHLY_INCOME_UBO = /ubo.rdlMonthlyIncomeId/;
export const REGEX_SOURCE_OF_FUND_UBO = /ubo.sourceOfFundId/;
export const REGEX_CUSTOMER_KEY_UBO = /ubo.customerRelationKey/;
/* ====== */

/* Network status code */
export const CONTENT_TOO_LARGE = 413;
export const UNKNOWN_ERROR = 520;
/* ===== */

/* Message Error */
export const DEFAULT_ERROR_MESSAGE = 'Terjadi Kesalahan';
/* ====== */

export const MAX_UPLOAD_SIZE = 2 * 1024 * 1024; // 2MB
export const BYTES_IN_A_MEGABYTE = 1024 * 1024;
export const MAX_UPLOAD_SIZE_10MB = 10 * 1024 * 1024;

/* Campaign List Filter */
export const DEFAULT_INPUT_RANGE_VALUE_MIN = 12;
export const DEFAULT_INPUT_RANGE_VALUE_MAX = 20;
export const TENOR_RANGE_1 = '30 - 45';
export const TENOR_RANGE_2 = '46 - 75';
export const TENOR_RANGE_3 = '> 75';
/* ===== */

export const PROVINCE_LEVEL = '1';
export const CITY_LEVEL = '2';
export const DISTRICT_LEVEL = '3';
export const VILLAGE_LEVEL = '4';
export const OTHER_VALUE_SELECT_OPTION = '99';
export const OPTION_OTHER_NAME_LIST = ['Lainnya', 'Other'];

export const AGRIBUSINESS_TYPE = 'Agribusiness';
export const EXPIRED_RESPONSE_MESSAGE = 'Unauthorized';
export const AGRI_USER_STATUS_REGISTER = {
  ACTIVE: 'active',
  NEW: 'new',
  REGISTERED: 'registered'
};

export const LIST_TYPE_FUNDER_BADAN_HUKUM = [
  AUTHENTICATION_TYPE.FUNDER_BADAN_HUKUM,
  AUTHENTICATION_TYPE.FUNDER_BADAN_HUKUM_FI
];

export const LIST_STEP_COMPLETE_PROFILE_INSTITUTIONAL = [
  {
    id: 1,
    translationTitle: 'completeProfile.label1',
    title: 'Data Perwakilan Perusahaan'
  },
  {
    id: 2,
    translationTitle: 'completeProfile.label2',
    title: 'Data Perusahaan'
  },
  {
    id: 3,
    translationTitle: 'completeProfile.label3',
    title: 'Data Dokumen Perusahaan'
  },
  {
    id: 4,
    translationTitle: 'completeProfile.label4',
    title: 'Data Manajemen Perusahaan'
  },
  {
    id: 5,
    translationTitle: 'completeProfile.label5',
    title: 'Ringkasan & Komitmen Keanggotaan'
  }
];

export const WNI = '1';
export const WNA = '2';

export const LIST_DOCUMENT_FETCH_WITHOUT_ID: DocumentType[] = [
  'ktp',
  'npwp',
  'selfie',
  'pic-poa',
  'est-deed',
  'siup',
  'tdp',
  'trx-report',
  'company-npwp',
  'nda',
  'pks',
  'skb'
];

export const LIST_DOC_MIME_TYPE = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export const DEFAULT_VALUE_AMENDMENT = {
  id: '',
  typeKey: null,
  docId: '',
  skMenkumhamDocId: ''
};

export const DEFAULT_VALUE_MANAGEMENT_FORM = {
  lastAmendmentDeedDate: '',
  amendmentDeeds: [DEFAULT_VALUE_AMENDMENT],
  hasNib: '',
  nibNumber: '',
  nibDocId: '',
  siupNumber: '',
  siupDocId: '',
  tdpNumber: '',
  tdpDocId: '',
  trxReportDocId: '',
  ndaDocId: '',
  pksDocId: '',
  skbStartDate: '',
  skbEndDate: '',
  skbDocId: ''
};

export const DEFAULT_VALUE_CO_MANAGEMENT = {
  name: '',
  citizenshipId: '',
  ktpNumber: '',
  ktpDocId: '',
  npwpNumber: '',
  npwpDocId: '',
  npwpOwnership: null,
  passportNumber: '',
  passportDocId: '',
  country: null,
  occupationPosition: null,
  occupationPositionOthers: '',
  email: ''
};

export const DEFAULT_VALUE_SHAREHOLDER = {
  characterType: null,
  citizenshipId: '',
  name: '',
  ktpNumber: '',
  ktpDocId: '',
  npwpNumber: '',
  npwpDocId: '',
  npwpOwnership: null,
  passportNumber: '',
  passportDocId: '',
  country: null,
  ownershipPercentage: 0
};

export const DEFAULT_VALUE_COMPANY_MANAGEMENT_FORM = {
  coManagements: [DEFAULT_VALUE_CO_MANAGEMENT],
  shareholders: [DEFAULT_VALUE_SHAREHOLDER],
  ubo: {
    id: '',
    name: '',
    citizenshipId: '',
    ktpNumber: '',
    ktpDocId: '',
    passportNumber: '',
    passportDocId: '',
    rdlJob: null,
    countryId: null,
    rdlJobOthers: '',
    sourceOfFund: null,
    rdlMonthlyIncome: null,
    customerRelationId: '',
    supportingDocId: ''
  }
};

export const DEFAULT_VALUE_COMPANY_PIC = {
  isCompanyPic: '',
  companyPoaDocId: '',
  ktpDocId: '',
  npwpDocId: '',
  selfieDocId: '',
  ktpNumber: '',
  npwpNumber: '',
  title: null,
  name: '',
  birthPlace: '',
  city: null,
  province: null,
  ktpIssuingCity: '',
  birthDate: '',
  village: null,
  address: '',
  rt: '',
  rw: '',
  occupationPosition: null,
  subdistrict: null,
  postalCode: '',
  emailPic: '',
  phonePic: '',
  emergencyPhoneName: '',
  emergencyPhoneNumber: ''
};

export const DEFAULT_VALUE_COMPANY_DATA = {
  corporateType: null,
  corporateTypeOthers: '',
  companyType: null,
  companyTypeOthers: '',
  companyName: '',
  establishmentPlace: '',
  establishmentDate: '',
  establishmentDeedNumber: '',
  establishmentDeedDate: '',
  establishmentDeedDocId: '',
  companyAddress: '',
  companyProvince: null,
  companyCity: null,
  companyPhone: '',
  companyWebsite: '',
  companyEmail: '',
  companyDesc: '',
  companyNpwpNumber: '',
  companyNpwpDocId: '',
  companyIndustry: null,
  companySubIndustry: null,
  companySubIndustryOthers: '',
  annualIncome: null,
  sourceOfFund: null
};

export const OCR_TYPE_TO_OCR_DATA: { [key in DocumentType]?: keyof OCRData } = {
  ktp: 'ktp',
  npwp: 'npwp',
  selfie: 'selfie'
};

export const STEP_COMPANY_PIC = 1;
export const STEP_COMPANY_DATA = 2;
export const STEP_COMPANY_DOCUMENT = 3;
export const STEP_COMPANY_MANAGEMENT = 4;
export const STEP_COMPANY_SUMMARY = 5;

export const DEFAULT_VALUE_RESPONSE_COMPANY_PIC = {
  address: '',
  birthDate: '',
  birthPlaceId: null,
  cityId: null,
  companyPoaDocId: '',
  emailPic: '',
  emergencyPhoneName: '',
  emergencyPhoneNumber: '',
  birthPlace: '',
  provinceId: '',
  isCompanyPic: false,
  ktpDocId: '',
  ktpIssuingCity: '',
  ktpNumber: '',
  name: '',
  npwpDocId: '',
  npwpNumber: '',
  occupationPositionKey: null,
  phonePic: '',
  rt: '',
  rw: '',
  selfieDocId: '',
  subdistrictId: null,
  titleId: null,
  villageId: null
};

export const DEFAULT_VALUE_COMPANY_PROFILE = {
  corporateTypeId: null,
  corporateTypeOthers: '',
  companyTypeId: null,
  companyTypeOthers: '',
  companyName: '',
  establishmentPlace: '',
  establishmentDate: '',
  establishmentDeedNumber: '',
  establishmentDeedDate: '',
  establishmentDeedDocId: '',
  companyNpwpDocId: '',
  companyAddress: '',
  companyProvinceId: null,
  companyCityId: null,
  companyEmail: '',
  companyPhone: '',
  companyWebsite: '',
  companyDesc: '',
  companyNpwpNumber: '',
  companyIndustryId: null,
  companySubIndustryId: null,
  companySubIndustryOthers: '',
  subdistrictId: null,
  annualIncomeId: null,
  sourceOfFundId: null
};

export const DEFAULT_VALUE_FORM_COMPANY_DOCUMENT = {
  lastAmendmentDeedDate: '',
  amendmentDeeds: [],
  hasNib: false,
  nibNumber: null,
  siupNumber: null,
  tdpNumber: null,
  skbStartDate: null,
  skbEndDate: null,
  nibDocId: null,
  siupDocId: null,
  tdpDocId: null,
  trxReportDocId: '',
  ndaDocId: null,
  pksDocId: null,
  skbDocId: null
};

export const DEFAULT_VALUE_FORM_COMPANY_MANAGEMENT = {
  coManagements: [],
  shareholders: [],
  ubo: {
    id: '',
    name: null,
    citizenshipId: '',
    ktpNumber: null,
    ktpDocId: '',
    passportNumber: null,
    passportDocId: '',
    rdlJobId: null,
    countryId: null,
    rdlJobOthers: null,
    sourceOfFundId: null,
    rdlMonthlyIncomeId: null,
    customerRelationKey: null,
    supportingDocId: ''
  }
};

export const DEFAULT_VALUE_RESPONSE_COMPANY_PROFILE: ProfileCompanyResponse = {
  companyPic: DEFAULT_VALUE_RESPONSE_COMPANY_PIC,
  company: DEFAULT_VALUE_COMPANY_PROFILE,
  companyDocument: DEFAULT_VALUE_FORM_COMPANY_DOCUMENT,
  companyManagement: DEFAULT_VALUE_FORM_COMPANY_MANAGEMENT
};
