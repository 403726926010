import dayjs from 'dayjs';
import UrlPattern from 'url-pattern';
import { parse, format as formatDateFns } from 'date-fns';
import {
  I18NEXT_LANG,
  REGEX_LEADING_ZERO,
  REGEX_ONLY_NUM,
  REGEX_REMOVE_NON_NUMERIC,
  REGEX_TITLE_CASE,
  REGEX_WITHOUT_LEADING_ZERO,
  REGEX_WITHOUT_THOUSAND
} from 'commons/constants/variable';
import { server } from 'commons/constants/config';
import { APP_IMAGE_GET } from 'commons/constants/endpoints';
import { getLocalStorage } from 'utils/localStorage';

const { api } = server;

export const formatDate = (date: string | number, format = 'DD MMM YYYY') => {
  if (!date) return '-';

  const getLang = getLocalStorage(I18NEXT_LANG) ?? 'id';
  return dayjs(date).locale(getLang).format(format);
};

export const formatTextTitleCase = (text: string) => {
  return text.replace(REGEX_TITLE_CASE, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const formatOnlyNumbers = (value: string) => {
  return value.replace(REGEX_ONLY_NUM, '');
};

export const formatLeadingZeroAndPrefixPhone = (
  value: string,
  prefix: string
) => {
  let newValue = value;
  newValue = newValue.replace(REGEX_WITHOUT_LEADING_ZERO, '');
  const pattern2 = `^(\\+${prefix}|${prefix})`;
  newValue = newValue.replace(new RegExp(pattern2, 'gi'), '');
  return newValue;
};

export const formatPhoneNumberToLeadingZero = (phone: string): string => {
  if (phone.startsWith('62')) {
    return `0${phone.slice(2)}`;
  }
  return phone;
};

export const formatWithoutThousand = (value: string) => {
  return value.replace(REGEX_WITHOUT_THOUSAND, '');
};

export const formatFormData = (value: any) => {
  const formData = new FormData();

  for (const key of Object.keys(value)) {
    formData.append(key, value[key]);
  }

  return formData;
};

export const formatToIDR = (idr: number, withIDR = true): string => {
  if (idr === null || idr === undefined) {
    return '-';
  }

  const parsed = idr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return `${withIDR ? 'Rp' : ''}${parsed}`;
};

export const formatNumberText = (value: string) => {
  const text = value.replace(/\D/g, '');
  return text;
};

export const formatConfigUrlImage = (id = '') => {
  let newPath = '';

  const pattern = new UrlPattern(APP_IMAGE_GET[1]);
  newPath = pattern.stringify({ idCampaign: id });

  return api + newPath;
};

export const formatNPWP = (value: string) => {
  const cleaned = value.replace(/\D/g, '');

  const match = cleaned.match(
    /(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1})?(\d{1,3})?(\d{1,3})?/
  );
  if (!match) {
    return cleaned;
  }

  const [, part1, part2, part3, part4, part5, part6] = match;

  let formatted = part1 || '';
  if (part2) {
    formatted += `.${part2}`;
  }
  if (part3) {
    formatted += `.${part3}`;
  }
  if (part4) {
    formatted += `.${part4}`;
  }
  if (part5) {
    formatted += `-${part5}`;
  }
  if (part6) {
    formatted += `.${part6}`;
  }

  return formatted;
};

export const formatRTRW = (value: string) => {
  if (!value) return '';

  const initalValue = value.replace(REGEX_REMOVE_NON_NUMERIC, '');
  if (initalValue === '0' || initalValue === '0000') {
    return '000';
  }

  const parsedValue = initalValue.replace(REGEX_LEADING_ZERO, '');
  if (parsedValue === '') {
    return '';
  }

  const maskedValue = parsedValue.padStart(3, '0');
  return maskedValue;
};

export const formatPhoneNumber = (value: string) => {
  const cleaned = value.replace(/\D/g, '');

  let formatted = cleaned;
  if (cleaned.length > 0) {
    formatted = `(${cleaned.slice(0, 3)}`;
  }
  if (cleaned.length >= 4) {
    formatted += `) ${cleaned.slice(3, 6)}`;
  }
  if (cleaned.length >= 7) {
    formatted += `-${cleaned.slice(6, 10)}`;
  }

  return formatted;
};

export const formatDateStringForm = (
  value: string | null,
  format = 'yyyy-MM-dd'
): string => {
  if (!value) return '';
  const parsedValue = parse(value, 'dd-MM-yyyy', new Date());
  return formatDateFns(parsedValue, format);
};
